import React, { useEffect, useState, useRef } from 'react';
import "./cart.min.css";
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { selectPlan } from '../../Redux/slices/selectedPlanSlice';
import LnModule from '../LandingModule/LnModule';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../landing/loder/Loader';
import { removeAddonModule, addAddonModule, assignAddonModules } from '../../Redux/slices/addonModulesSlice';
import { Link, useNavigate } from 'react-router-dom';
import { purchasePost, getAllOrders, getAllPlan, orderUpdate } from '../../services/User/api';
import "../UserDashboard/user-dashboard.min.css"
import { logOut } from '../../services/User/api';
import { orderPost } from '../../services/User/api';
import countryCodeCurrencyMap from '../../services/utils/countryCurrencyMap.json';
import currencySymbolMap from '../../services/utils/currencySymbol.json';
import axios from 'axios';

const countryInfo = JSON.parse(localStorage.getItem('countryInfo'));


const CurrencyConverter = ({ amount }) => {
    const [finalAmount, setFinalAmount] = useState(amount);
    const [convertedAmount, setConvertedAmount] = useState(null);

    const url = `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=inr,${countryCodeCurrencyMap[countryInfo?.countryCode]}`;
    useEffect(() => {
        const fetchConversionRate = async () => {
            try {
                const response = await axios.get(url);
                const baseCurrency = Number(response.data.bitcoin.inr);
                const currencyToConvert = Number(response.data.bitcoin[`${countryCodeCurrencyMap[countryInfo?.countryCode].toLowerCase()}`]);
                const convertedCurrency = Number(finalAmount) * currencyToConvert / baseCurrency;
                setConvertedAmount(Math.ceil(convertedCurrency));
            } catch (error) {
                console.log("Error:",error.message);
            }
        };

        if (finalAmount > 0) {
            fetchConversionRate();
        }
    }, [finalAmount]);

    return (
        <span className="">
            {currencySymbolMap[countryInfo?.countryCode]}<span>
                {convertedAmount ? convertedAmount.toFixed(2) : finalAmount}
            </span>
        </span>
    );
};

const DollarCurrencyConverter = ({ amount }) => {
    const [finalAmount, setFinalAmount] = useState(amount);
    const [convertedAmount, setConvertedAmount] = useState(null);

    const url = `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=inr,usd`;
    useEffect(() => {
        const fetchConversionRate = async () => {
            try {
                const response = await axios.get(url);
                const baseCurrency = Number(response.data.bitcoin.inr);
                const currencyToConvert = Number(response.data.bitcoin.usd);
                const convertedCurrency = Number(finalAmount) * currencyToConvert / baseCurrency;
                console.log(convertedCurrency, response, 'convertedCurrencyDollar')
                setConvertedAmount(Math.ceil(convertedCurrency));
                localStorage.setItem('finalDollarAmount',Math.ceil(convertedCurrency));
                localStorage.setItem('totalAmonuntInRupees',amount);
            } catch (error) {

            }
        };

        if (finalAmount > 0) {
            fetchConversionRate();
        }
    }, [finalAmount]);

    return (
        <span className="">
            {currencySymbolMap['US']}<span>
                {convertedAmount ? convertedAmount.toFixed(2) : finalAmount}
            </span>
        </span>
    );
};

const handleError = (error) => {
    console.error("Payment Error:", error);
    alert("An error occurred with the payment.");
};

const PayPalButton = ({ clientId, amount, onError, onSuccess }) => {
    console.log(clientId, amount);
    const paypalRef = useRef();

    useEffect(() => {
        // Load the PayPal JavaScript SDK
        const loadPayPalScript = () => {
            const script = document.createElement("script");
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`;
            script.addEventListener("load", () => {
                // Render the PayPal button after the SDK has loaded
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: { value: amount }
                                }
                            ]
                        });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        onSuccess(order);
                    },
                    onError: (err) => {
                        console.error("PayPal error:", err);
                        onError(err); // Callback to handle errors
                    }
                }).render(paypalRef.current);
            });
            document.body.appendChild(script);
        };

        loadPayPalScript();

        return () => {
            // Cleanup script when component unmounts
            document.body.querySelector("script[src*='paypal']").remove();
            // localStorage.getItem('finalDollarAmount') && localStorage.removeItem('finalDollarAmount');
            // localStorage.getItem('totalAmount') && localStorage.removeItem('totalAmount');
        };
    }, [clientId, amount, onError]);

    return <div ref={paypalRef} />;
};

function PurchasePage(props) {

    const [totalAmount, setTotalAmount] = useState(null);
    const token = localStorage.getItem('companyToken');
    const monthlyOrYearlyToggleValue = useSelector((state) => state.selectedPlan.toggle);
    const selectedPlan = useSelector((state) => state.selectedPlan);
    const [orders, setOrders] = useState([]);
    const [allOrders, setAllOrders] = useState(null);
    const [orderedPlan, setOrderedPlan] = useState({});
    const [orderedPlans, setOrderedPlans] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [loading, setLoading] = useState(false);
    const addonModules = useSelector((state) => state.addonModules.addonModules);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [addonRemoveShow, setAddonRemoveShow] = useState(false);
    const [selectedAddon, setSelectedAddon] = useState(null);

    const hideRemoveAddonModal = () => setAddonRemoveShow(false);
    const showRemoveAddonModal = (addon) => {
        setSelectedAddon(addon);
        setAddonRemoveShow(true);
    };

    const handleRemoveAddon = () => {
        if (selectedAddon) {
            dispatch(removeAddonModule({ id: selectedAddon.id }));
        }
        hideRemoveAddonModal();
    };

    const [addMoreModuleModalShow, setAddMoreModuleModalShow] = useState(false);

    const hideAddModuleModal = () => setAddMoreModuleModalShow(false);
    const showAddonModuleModal = () => setAddMoreModuleModalShow(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allorders = await getAllOrders(token);
                const [allOrdersObj] = allorders.data;
                const plansData = await getAllPlan();
                const displayablePlans = plansData.data.filter((plan)=>plan.display).map((data)=>data.id);
                const selectedPlan = allOrdersObj.Order_Details.filter((data)=>displayablePlans.includes(data.plan.id))[0]
                const planIds = allOrdersObj.Order_Details.map(data => data.plan.id);
                const unOrderedPlans = plansData.data.filter((item) => planIds.includes(item.id) && selectedPlan.plan.id !== item.id);
                const [orderedPlan] = unOrderedPlans;
                
                setOrderedPlan(selectedPlan || {});
                setAllOrders(allOrdersObj || {});
                setOrders(allorders.data.id);
                dispatch(assignAddonModules(unOrderedPlans));
                setOrderedPlans(plansData.data.filter((item) => item.id === planIds.includes(item.id)));
                dispatch(selectPlan(plansData.data.find((item) => item.id === allorders.data.plan.id)));
                setOrderData(allorders.data);
            } catch (err) {
                console.log(err);
            }
        };

        if (token) {
            fetchData();
        }
    }, []);


    const updateOrder = async () => {
        // await  orderPost(plan.id, planType, loginResponse.token);
    }

    const handlePurchase = async (paypalResponse) => {
        setLoading(true);
        let totalPlans = addonModules.map((module) => module.id);
        totalPlans.unshift(orderedPlan.plan.id);
        orderUpdate(allOrders.id, totalPlans, orderedPlan.plan_type, token).then((response) => {
            console.log(response,paypalResponse, 'response');
            const data = {
                order_ids: [allOrders.id],
                currency: paypalResponse.purchase_units[0].amount.currency_code,
                transaction_id: paypalResponse.id,
                intent: paypalResponse.intent,
                state:'',
                cart:'',
                payer_email: paypalResponse.payer.email_address,
                payer_first_name: paypalResponse.payer.name.given_name,
                payer_last_name: paypalResponse.payer.name.surname,
                payer_id: paypalResponse.payer.payer_id,
                payer_country_code: paypalResponse.payer.address.country_code,
                total_transaction_amount: paypalResponse.purchase_units[0].amount.value,
                payee_merchant_id: paypalResponse.purchase_units[0].payee.merchant_id,
                total_amount_in_rupees:localStorage.getItem('totalAmonuntInRupees'),
                full_response: JSON.stringify(paypalResponse)
            };
            return purchasePost(data, token);
        }).then((response) => {
            console.log(response, 'response')
            navigate('/user-dashboard')
        }).catch(error => {
            console.error('Failed to register or login company:', error.message);
        }).finally(() => {
            setLoading(false);
        });
        // try {
        //     const purchaseData = await purchasePost(orderId, token);
        // } catch (err) {
        //     console.log('Purchase failed:', err.message);
        // }
    };

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            {allOrders &&
                <section className="ln-cart-sec position-relative pt-2 ">
                    <div className="container d-flex flex-column ">
                        <div className="d-flex justify-content-end ml-2">
                            <header className="user-account-header-wrap" style={{ float: 'right' }}>
                                <div className="container  ">
                                    <div className="user-account-header-cont">
                                        <a className="gth-btn-outline d-flex align-items-right" onClick={(e) => { e.preventDefault(); logOut() }} style={{ cursor: 'pointer' }}>
                                            Sign Out <i className="fi fi-br-sign-out-alt mt-1 ms-2"></i>
                                        </a>
                                    </div>
                                </div>
                            </header>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className='w-100'>
                                    <h4 className="mb-2">Selected Plan</h4>
                                    <div className="selected-plan mb-4">
                                        <div className="select-plan-header">
                                            <h1 className="plan-name">
                                                {orderedPlan?.plan.plan_name}
                                            </h1>
                                            <div className="select-plan-price">
                                                <span className=""><span>
                                                    <CurrencyConverter amount={orderedPlan?.price} />
                                                </span></span>
                                            </div>
                                            <div>
                                                {orderedPlans ? 'Monthly' : "Annual"}
                                                Subscription<br />
                                                Renews <span>June 16, 2024</span>
                                            </div>
                                        </div>

                                        <div className="select-plan-details-wrap">
                                            <ul>
                                                {orderedPlan?.plan.description.split(',').map((feature, index) => (
                                                    <li key={index}>
                                                        <i className="fal fa-check" /> {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                            <div>
                                                <p className="mb-1 notes">
                                                    <mark className='bg-transparent text-danger'>**</mark> GST charges are included.
                                                </p>
                                                <p className="mb-1 notes">
                                                    <mark className='bg-transparent text-danger'>#</mark>Whatsapp API cost charge not included.
                                                </p>
                                                <p className="notes">
                                                    <mark className="bg-transparent text-dark">*</mark>
                                                    Storage is limited with plans. For additional storage contact support at <a href="mailto:support@growthh.com" className="gth-text-primary1">support@growthh.com</a>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h4 className="mb-0">Addon Module</h4>
                                        <label className="mb-0 gth-text-primary1 cursor-pointer" onClick={showAddonModuleModal}><i className="fi fi-br-plus me-2"></i>Add More</label>
                                    </div>
                                    <div className="addon-module-wrapper">
                                        {addonModules && addonModules.map((addOns) => (
                                            <div className="addon-module-item" key={addOns.id}>
                                                {console.log(addonModules)}
                                                <span className="module-icon"><i className="fi fi-sr-crm-computer"></i></span>
                                                <div className="ps-3">
                                                    <span className="module-name">{addOns?.plan_name}</span>
                                                    <p className="cart-short-desc">
                                                        {addOns?.description}
                                                    </p>
                                                </div>

                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip>
                                                            Remove
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="module-selected" onClick={() => showRemoveAddonModal(addOns)}><i className="fi fi-sr-circle-xmark"></i></button>
                                                </OverlayTrigger>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="plan-details-wrap">
                                    <h4 className="mb-2">Plan Details</h4>
                                    <div className="card plan-details-card">
                                        <div className="card-body p-4">
                                            <div className='alert alert-warning rounded-3'>
                                                The amount is converted to $USD
                                            </div>
                                            <table className="custom-table">
                                                <tr>
                                                    <th>
                                                        <span className="text-muted"><u>Plan</u></span><br />
                                                        {orderedPlan?.plan.plan_name}
                                                    </th>
                                                    <th>
                                                        <span className=""><span>

                                                            {/* {orderedPlan?.price} */}
                                                            {console.log(orderedPlan?.price,'price')}
                                                            <DollarCurrencyConverter amount={orderedPlan?.price} />

                                                        </span></span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="text-muted"><u>Addon Module</u></span><br />
                                                        <div className='d-flex flex-column'>
                                                            {addonModules.map((item) => (
                                                                <span key={item.id}>{item?.plan_name}<br /></span>
                                                            ))}
                                                        </div>
                                                    </th>
                                                    <th className='d-flex flex-column'>
                                                        {addonModules.map((item) => (
                                                            <span key={item.id} className=""> <span>{(selectedPlan.planType === "Monthly") ?  <DollarCurrencyConverter amount={item?.monthly_price}/> :  <DollarCurrencyConverter amount={item?.annual_price}/>}</span></span>
                                                        ))}
                                                    </th>
                                                </tr>
                                                <tr className="border-bottom tbl-foot">
                                                    <th>
                                                        <span className="text-dark total">Total</span>
                                                    </th>
                                                    <th>
                                                        {(addonModules && addonModules.length > 0) ? (
                                                            (() => {
                                                                const totalPrice = addonModules.reduce((acc, mod) => {
                                                                    const price = orderedPlan.plan_type === "Monthly" ? mod.monthly_price : mod.annual_price;
                                                                    return acc + price;
                                                                }, 0);

                                                                return (
                                                                    <span className=""><span>
                                                                        {/* {amountCalculator(1999)} */}
                                                                        <DollarCurrencyConverter amount={totalPrice + orderedPlan.price} />
                                                                        {/* {finalAmount > 0 ? finalAmount : totalPrice + orderedPlan.price} */}
                                                                    </span></span>
                                                                );
                                                            })()
                                                        ) : (
                                                            <span className=""><span>
                                                                {/* {amountCalculator(allOrders.price)} */}
                                                                {/* {finalAmount > 0 ? finalAmount : allOrders.price} */}
                                                                <DollarCurrencyConverter amount={allOrders.price} />
                                                            </span></span>
                                                        )}
                                                    </th>
                                                </tr>
                                            </table>
                                            {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                                <h4 className="mb-0">Addon Module</h4>
                                                <label className="mb-0 gth-text-primary1 cursor-pointer" onClick={showAddonModuleModal}><i className="fi fi-br-plus me-2"></i>Add More</label>
                                            </div>

                                            <div className="addon-module-wrapper">
                                                {orderedPlans && orderedPlans[0]?.modules.map((addOns) => (
                                                    <div className="addon-module-item" key={addOns.module.id}>
                                                        <span className="module-icon"><i className="fi fi-sr-crm-computer"></i></span>
                                                        <div className="ps-3">
                                                            <span className="module-name">{addOns?.module.module_name}</span>
                                                            <p className="cart-short-desc">
                                                                {addOns?.module.description}
                                                            </p>
                                                            <span>{addOns?.module.description}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div> */}
                                            <p className="my-3"><small>Next charge date: 24 June, 2025</small></p>
                                            <div className="text-center mt-3">
                                                {/* <button className="gtn-warning-btn text-uppercase"
                                                    onClick={() => handlePurchase(orders, token)}
                                                >purchase now</button> */}
                                                <PayPalButton
                                                    clientId="Aer90N1TWXJGUCEK12Wp1xgSMYNPIjgAR1zv96I3WcpIK-rISQStqZjKndrQ8MijiI7mXw9RO3MTp2H6"
                                                    amount={Number(localStorage.getItem('finalDollarAmount')).toFixed(2)}  // Example amount
                                                    onSuccess={handlePurchase}
                                                    onError={handleError}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                        </div>
                    </div >
                </section >
            }
            {/* {!monthlyOrYearlyToggleValue ? (
                <>
                </>
            ) : (
                <>
                    <section className="ln-cart-sec position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="mb-2">Selected Plan</h4>
                                    <div className="selected-plan mb-4">
                                        <div className="select-plan-header">
                                            <h1 className="plan-name">
                                                {selectedPlan?.plan.plan_name}
                                            </h1>
                                            <div className="select-plan-price">
                                                <span className="">&#8377; <span>
                                                    {selectedPlan?.toggle ? selectedPlan?.plan.monthly_price : selectedPlan?.plan.annual_price}
                                                </span></span>
                                            </div>
                                            <div>
                                                {selectedPlan?.toggle ? 'Monthly' : "Yearly"} 
                                                Subscription<br />
                                                Renews <span>June 16, 2024</span>
                                            </div>
                                        </div>

                                        <div className="select-plan-details-wrap">
                                            <ul>
                                    {selectedPlan?.plan?.description.split(',\n').map((feature, index) => (
                                        <li key={index}>
                                            <i className="fal fa-check" /> {feature}
                                        </li>
                                    ))}
                                </ul>
                                            <div>
                                                <p className="mb-1 notes">
                                                    <mark className='bg-transparent text-danger'>**</mark> GST charges are included.
                                                </p>
                                                <p className="mb-1 notes">
                                                    <mark className='bg-transparent text-danger'>#</mark>Whatsapp API cost charge not included.
                                                </p>
                                                <p className="notes">
                                                    <mark className="bg-transparent text-dark">*</mark>
                                                    Storage is limited with plans. For additional storage contact support at <a href="mailto:support@growthh.com" className="gth-text-primary1">support@growthh.com</a>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="plan-details-wrap">
                                        <h4 className="mb-2">Plan Details</h4>
                                        <div className="card plan-details-card">
                                            <div className="card-body p-4">
                                                <table className="custom-table">
                                                    <tr>
                                                        <th>
                                                            <span className="text-muted"><u>Plan</u></span><br />
                                                            {selectedPlan?.plan.plan_name}
                                                        </th>
                                                        <th>
                                                            <span className="">&#8377; <span>
                                                                {selectedPlan?.toggle ? selectedPlan?.plan.monthly_price : selectedPlan?.plan.annual_price}
                                                            </span></span>
                                                        </th>
                                                    </tr>
                                                    <tr className="border-bottom tbl-foot">
                                                        <th>
                                                            <span className="text-dark total">Total</span>
                                                        </th>
                                                        <th>
                                                            <span className="">&#8377; <span>
                                                                {selectedPlan?.toggle ? selectedPlan?.plan.monthly_price : selectedPlan?.plan.annual_price}
                                                            </span></span>
                                                        </th>
                                                    </tr>
                                                </table>
                                                <p className="my-3"><small>Next charge date: 24 June, 2025</small></p>
                                                <div className="text-center mt-3">
                                                    <button className="gtn-warning-btn text-uppercase"
                                                    onClick={handlePurchase}
                                                    >purchase now</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )} */}




            {/* module remove modal */}
            <Modal show={addonRemoveShow} onHide={hideRemoveAddonModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className="bg-warning-light rounded-top-left-10 rounded-top-right-10">
                    <div className="delete-warning-wrap text-center">
                        <div className="delete-warning-icon mb-0 mt-2">
                            <i className="fi fi-sr-triangle-warning text-warning"></i>
                        </div>
                        <h4 className="text-dark mb-2">Are you sure?</h4>
                        <p className="text-muted">Do you really want to remove this module? <br />This process cannot be undone.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={hideRemoveAddonModal} className="btn btn-secondary">
                        Close
                    </button>
                    <button onClick={handleRemoveAddon} className="btn btn-exp-red">
                        Remove
                    </button>
                </Modal.Footer>
            </Modal>
            {/* module remove modal end */}
            {/* module add modal start */}
            <Modal show={addMoreModuleModalShow} onHide={hideAddModuleModal} backdrop="static" keyboard={false} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Modules</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <LnModule addOns={addonModules} />

                    <div className="text-center mt-3">
                        <button className="gtn-success-btn text-uppercase" onClick={hideAddModuleModal}>
                            Confirm
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PurchasePage;
