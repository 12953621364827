import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

function Navigation({ lang = false }) {
    const scrollToSection = (id) => {
        window.scrollTo({
            top: document.getElementById(id).offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <ul>
                <li>
                    <a href="/">
                        Home
                    </a>
                </li>
                {/* <li>
                    <a href="/#features" onClick={() => scrollToSection('features')}>Features</a>
                </li> */}
                <li>
                    <a href="/">
                        Features
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Bold"
                            viewBox="0 0 24 24"
                            width={12}
                            height={12}
                            fill="currentColor"
                            className='ms-1'
                        >
                            <path d="M1.51,6.079a1.492,1.492,0,0,1,1.06.44l7.673,7.672a2.5,2.5,0,0,0,3.536,0L21.44,6.529A1.5,1.5,0,1,1,23.561,8.65L15.9,16.312a5.505,5.505,0,0,1-7.778,0L.449,8.64A1.5,1.5,0,0,1,1.51,6.079Z" />
                        </svg>

                    </a>
                    <ul className="sub-menu">
                        <li>
                            <a href="/task-management-software">Task Management Software</a>
                        </li>
                        <li>
                            <a href="/business-management-software">Business Management Software</a>
                        </li>
                        <li>
                            <a href="/business-task-automation-software">Business Task Automation Software</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href='https://growthh.com/blog/articles/'>Blog</a>
                </li>
                <li>
                    <a href="/contact-us">Contact</a>
                </li>
            </ul>
            {/* {lang ? (
                <ul>
                    <li>
                        <a href="#">
                            أنا 
                            
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <a href='#'>الصفحة الرئيسية 1</a>
                            </li>
                            <li>
                                <a href="/home-two">الصفحة الرئيسية 2</a>
                            </li>
                            <li>
                                <a href="/home-three">المنزل 3</a>
                            </li>
                            <li>
                                <a href="/home-four">المنزل 4</a>
                            </li>
                            <li>
                                <a href="/home-five">المنزل 5</a>
                            </li>
                            <li>
                                <a href="/home-six">الصفحة الرئيسية 6</a>
                            </li>
                            <li>
                                <a href="/home-seven">المنزل 7</a>
                            </li>
                            <li>
                                <a href="/home-eight">الصفحة الرئيسية 8</a>
                            </li>
                            <li>
                                <a href="/home-dark">الصفحة الرئيسية الظلام</a>
                            </li>
                            <li>
                                <a href="/home-rtl">الصفحة الرئيسية Rtl</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/service">خدمة</a>
                    </li>
                    <li>
                        <a href="#">
                            الصفحات 
                            
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <a href="/about-us">عن</a>
                            </li>
                            <li>
                                <a href="/error">خطأ</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            أخبار 
                            
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <a href="/news">صفحة الأخبار </a>
                            </li>
                            <li>
                                <a href="/news/single-news">أخبار واحدة</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/contact">اتصل</a>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <a href="/">
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#features" onClick={() => scrollToSection('features')}>Features</a>
                    </li>
                    <li>
                        <a href="">Contact</a>
                    </li>
                </ul>
            )} */}
        </>
    );
}

export default Navigation;
