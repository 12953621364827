import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../landing/loder/Loader';
import { setPassword } from '../../services/User/api'; // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPassword as setPasswordRedux } from '../../Redux/slices/passwordSlice';
import Swal from 'sweetalert2';

function SetPassword() {

    const navigate = useNavigate();

    const email = useSelector((state) => state.email.email);
    const dispatch = useDispatch();
    console.log("email", email);
    const [password, setPasswordValue] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({
        password: '',
    });
    const validateUserInfo = () => {
        let isValid = true;
        let newErrors = { ...errors };

        // Password validation
        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            newErrors.password = 'Password must contain at least one uppercase letter';
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            newErrors.password = 'Password must contain at least one number';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            newErrors.password = 'Password must contain at least one special character';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        // Update errors state (assuming you have a setErrors function to update the state)
        setErrors(newErrors);
        return isValid;

    };
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleChangePassword = (e) => {
        dispatch(setPasswordRedux(e.target.value));
        setPasswordValue(e.target.value);
    };

    const handleChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateUserInfo()) {
            if (password !== confirmPassword) {
                Swal.fire('Passwords do not match');
                return;
            }

            try {
                console.log(password, email);
                await setPassword(email, password);
                Swal.fire('Password set successfully');
                navigate('/register'); // Redirect to login or another appropriate page
            } catch (error) {
                Swal.fire(error.message);
            }
        }
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <div className='login-auth-wrap'>
                    <div className='container'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div className='loginBox'>
                                    <div className="logo-wrap">
                                        <a href="/"><img src={process.env.PUBLIC_URL + 'assets/images/logo-navy.png'} alt="Growthh" className='img-fluid' /></a>
                                    </div>
                                    <h1 className='login-title mb-2'>
                                        Set Password
                                    </h1>
                                    <div class="alert alert-warning f-s-14 d-flex align-items-start" role="alert">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width={30}
                                            height={30}
                                            x={0}
                                            y={0}
                                            viewBox="0 0 512 512"
                                            style={{ enableBackground: "new 0 0 512 512" }}
                                            xmlSpace="preserve"
                                            className=""
                                        >
                                            <g>
                                                <linearGradient
                                                    id="a"
                                                    x1="133.24"
                                                    x2="377.25"
                                                    y1="44.08"
                                                    y2="465.3"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop offset={0} stopColor="#faad5f" />
                                                    <stop offset=".09" stopColor="#fba659" />
                                                    <stop offset=".69" stopColor="#fe7e35" />
                                                    <stop offset={1} stopColor="#ff6f27" />
                                                </linearGradient>
                                                <linearGradient
                                                    id="b"
                                                    x1="174.43"
                                                    x2="420.38"
                                                    y1="489.57"
                                                    y2="219.4"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop offset={0} stopColor="#ff6f27" />
                                                    <stop offset={1} stopColor="#ff5e1f" />
                                                </linearGradient>
                                                <linearGradient
                                                    id="c"
                                                    x1="146.71"
                                                    x2="349.33"
                                                    y1="482.32"
                                                    y2="308.02"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop offset={0} stopColor="#ff6b2c" />
                                                    <stop offset={1} stopColor="#ff2d0f" />
                                                </linearGradient>
                                                <g data-name="Layer 2">
                                                    <g data-name="Layer 1 copy">
                                                        <g data-name={55}>
                                                            <circle
                                                                cx={256}
                                                                cy={256}
                                                                r={256}
                                                                fill="url(#a)"
                                                                opacity={1}
                                                                data-original="url(#a)"
                                                                className=""
                                                            />
                                                            <path
                                                                fill="url(#b)"
                                                                d="M512 256c0 141.4-114.6 256-256 256A255.71 255.71 0 0 1 42.84 397.8c146.43-2.22 204.63-90.6 204.63-90.6 83.31-126 185.14-152.89 241.58-157.27A254.62 254.62 0 0 1 512 256z"
                                                                opacity={1}
                                                                data-original="url(#b)"
                                                            />
                                                            <path
                                                                fill="url(#c)"
                                                                d="M483.67 373.22A256.11 256.11 0 0 1 30.35 377c52 13.4 141.2 27.28 201.44-12.91 0 0 153.89-115.71 251.88 9.13z"
                                                                opacity={1}
                                                                data-original="url(#c)"
                                                                className=""
                                                            />
                                                            <g fill="#fff">
                                                                <rect
                                                                    width="55.5"
                                                                    height="176.72"
                                                                    x="228.26"
                                                                    y="209.28"
                                                                    rx="27.75"
                                                                    fill="#ffffff"
                                                                    opacity={1}
                                                                    data-original="#ffffff"
                                                                    className=""
                                                                />
                                                                <path
                                                                    d="M256 187.6a30.8 30.8 0 1 1 30.62-30.8A30.75 30.75 0 0 1 256 187.6z"
                                                                    fill="#ffffff"
                                                                    opacity={1}
                                                                    data-original="#ffffff"
                                                                    className=""
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>


                                        <span className='ps-2'>Must be 8 or more characters and contain at least 1 number and 1 special character.</span>
                                    </div>

                                    <div className='form-wrap'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            <div className="form-group mb-4">
                                                <label className="form-label">Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="fi fi-ss-key" /></div>
                                                    </div>
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        value={password}
                                                        onChange={handleChangePassword}
                                                    />
                                                    <div className="input-group-text bg-white">
                                                        <i className={`bi ${showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={togglePasswordVisibility} style={{ marginRight: 5, cursor: 'pointer', color: '#777' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            {errors.password && <div className="text-danger">{errors.password}</div>}
                                            <div className="form-group mb-4">
                                                <label className="form-label">Confirm Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="fi fi-ss-key" /></div>
                                                    </div>
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        value={confirmPassword}
                                                        onChange={handleChangeConfirmPassword}
                                                    />
                                                    <div className="input-group-text bg-white">
                                                        <i className={`bi ${showConfirmPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={toggleConfirmPasswordVisibility} style={{ marginRight: 5, cursor: 'pointer', color: '#777' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-exp-primary-dark w-100 f-s-18">Confirm</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 login-right-col'>
                                <div className='login-right-wrap'>
                                    <div className='text-end imgBx'>
                                        <img src={process.env.PUBLIC_URL + 'assets/images/login-right.webp'} alt="login" />
                                    </div>
                                    <div className='textBx'>
                                        "Before Growthh, my to-do lists were scattered all around! Now, everything is in order and in one place."
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetPassword;
