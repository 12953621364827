import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../landing/loder/Loader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { registerCompany,registerCompanyToBms } from '../../services/User/api';
import { setCompanyData } from '../../Redux/slices/companyRegistrationSlice';
import { loginCompany,orderPost } from '../../services/User/api';


function Register(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailFromStore = useSelector((state)=>state.email.email);
  const password = useSelector((state) => state.password.password);
  const plan = useSelector((state) => state.selectedPlan.plan);
  const addonModules = useSelector((state) => state.addonModules.addonModules);
  const planType = useSelector((state) => state.selectedPlan.planType);

  const [errors, setErrors] = useState({
    companyName: '',
    companyOwnerName: '',
    email: '',
    location: '',
    companyPhone: '',
    companyWhatsApp: '',
    companyStrength: '',
    companyTurnover: '',
    companyLogo: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    contactPersonWhatsApp: ''
  });

  const validateCompanyInfo = () => {
    let isValid = true;
    let newErrors = {...errors};
  
    if (!companyName) {
      newErrors.companyName = 'Company Name is required';
      isValid = false;
    } else {
      newErrors.companyName = '';
    }
  
    if (!companyOwnerName) {
      newErrors.companyOwnerName = 'Company Owner Name is required';
      isValid = false;
    } else {
      newErrors.companyOwnerName = '';
    }
  
    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    } else {
      newErrors.email = '';
    }
  
    if (!location) {
      newErrors.location = 'Location is required';
      isValid = false;
    } else {
      newErrors.location = '';
    }
  
    if (!companyPhone) {
      newErrors.companyPhone = 'Company Phone No. is required';
      isValid = false;
    } else {
      newErrors.companyPhone = '';
    }
  
    if (!companyWhatsApp) {
      newErrors.companyWhatsApp = 'Company WhatsApp No. is required';
      isValid = false;
    } else {
      newErrors.companyWhatsApp = '';
    }

    if (!companyStrength) {
      newErrors.companyStrength = 'Company Strength is required';
      isValid = false;
    } else {
      newErrors.companyStrength = '';
    }
  
    if (!companyTurnover) {
      newErrors.companyTurnover = 'Company Turnover is required';
      isValid = false;
    } else {
      newErrors.companyTurnover = '';
    }
  
    if (!companyLogo) {
      newErrors.companyLogo = 'Company Logo is required';
      isValid = false;
    } else {
      newErrors.companyLogo = '';
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const validateContactPersonInfo = () => {
    let isValid = true;
    let newErrors = {...errors};
  
    if (!contactPersonName) {
      newErrors.contactPersonName = 'Contact Person Name is required';
      isValid = false;
    } else {
      newErrors.contactPersonName = '';
    }
  
    if (!contactPersonEmail) {
      newErrors.contactPersonEmail = 'Contact Person Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(contactPersonEmail)) {
      newErrors.contactPersonEmail = 'Email is invalid';
      isValid = false;
    } else {
      newErrors.contactPersonEmail = '';
    }
  
    if (!contactPersonPhone) {
      newErrors.contactPersonPhone = 'Contact Person Phone No. is required';
      isValid = false;
    } else {
      newErrors.contactPersonPhone = '';
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const handleNextTab = (handleNext, tabIndex) => {
    if (tabIndex === 0 && validateCompanyInfo()) {
      handleNext();
    } else if (tabIndex === 1 && validateContactPersonInfo()) {
      handleNext();
    }
  };

  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companyOwnerName, setCompanyOwnerName] = useState('');
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState(emailFromStore);
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyWhatsApp, setCompanyWhatsApp] = useState('');
  const [companyStrength, setCompanyStrength] = useState('');
  const [companyTurnover, setCompanyTurnover] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [contactPersonName, setContactPersonName] = useState('');
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [contactPersonPhone, setContactPersonPhone] = useState('');
  const [contactPersonWhatsApp, setContactPersonWhatsApp] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleComplete = () => {
    setLoading(true);
    
    const formData = new FormData();

    formData.append('company_name', companyName);
    formData.append('contact_no', companyPhone);
    formData.append('whats_app_number', companyWhatsApp);
    formData.append('total_employees', Number(companyStrength));
    formData.append('turn_over', companyTurnover);
    formData.append('email', email);
    formData.append('location', location);
    formData.append('owner_name', companyOwnerName);
    formData.append('logo', companyLogo);
    formData.append('contact_person_name', contactPersonName);
    formData.append('contact_person_email', contactPersonEmail);
    formData.append('contact_person_contact_no', contactPersonPhone);
    formData.append('contact_person_whats_app_number', contactPersonWhatsApp);
    registerCompany(formData)
    .then(response => {
      localStorage.setItem('regUser', JSON.stringify(response));
      return loginCompany(email, password);
    })
    .then(loginResponse => {
      localStorage.setItem('companyToken', loginResponse.token);
      let totalPlans = addonModules.map((module)=> module.id); 
      console.log(totalPlans,plan,'totalPlans');
      totalPlans.unshift(plan.id);
      console.log(totalPlans,'totalPlans');
      return orderPost(totalPlans, planType, loginResponse.token);
    })
    .then(orderResponse => {
      console.log(orderResponse, 'orderResponse');
      navigate('/purchase');
    })
    .catch(error => {
      console.error('Failed to register or login company:', error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  
  };

  // const handleCompanyRegistration = async (formData, email, password, plan, planType, navigate, setLoading) => {
  //   try {
  //     setLoading(true);
  
  //     const response = await registerCompany(formData);
  //     console.log("res", response);
  //     localStorage.setItem('regUser', JSON.stringify(response));
  
  //     const loginResponse = await loginCompany(email, password);
  //     console.log(loginResponse.token, "Login response after registration");
  //     localStorage.setItem('companyToken', loginResponse.token);
  
  //     const orderResponse = await orderPost(plan.id, planType, loginResponse.token);
  //     console.log(orderResponse, 'orderResponse');
      
  //     navigate('/purchase');
  //   } catch (error) {
  //     console.error('Failed to register or login company:', error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const handleSkip = () => {
    console.log("Skip!");
    // Handle form completion logic here
  };

  const backTemplate = (handlePrevious) => {
    return (
      <button className="action-btn back-button" onClick={handlePrevious}>
        Back
      </button>
    );
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCompanyLogo(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
        <div className='login-auth-wrap'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-lg-5 col-md-6 col-sm-12'>
                <div className='loginBox'>
                  <div className="logo-wrap">
                    <a href="/"><img src={process.env.PUBLIC_URL + 'assets/images/logo-navy.png'} alt="Growthh" className='img-fluid' /></a>
                  </div>
                  <h1 className='login-title'>
                    Join our community and unlock a world of possibilities -
                    <span className='position-relative sign-in me-2'>
                      <span>SIGN UP!</span>
                      <svg viewBox="0 0 156 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-blue login-svg"><path d="M156 41C156 50.9411 147.89 58.735 138.408 55.7499C135.073 54.7001 131.284 53.8018 124.799 53.8018C109.2 53.8018 109.197 59 93.598 59C77.9988 59 77.9987 53.8018 62.3995 53.8018C46.8003 53.8018 46.7977 59 31.1985 59C24.7151 59 20.9263 58.102 17.5923 57.0526C8.1099 54.0677 0 45.7429 0 35.8018V18C0 8.05888 8.1099 0.265901 17.5923 3.2508C20.9263 4.30029 24.7151 5.19824 31.1985 5.19824C46.7977 5.19824 46.7977 0 62.3995 0C78.0013 0 77.9988 5.19824 93.598 5.19824C109.197 5.19824 109.197 0 124.799 0C131.284 0 135.073 0.898168 138.408 1.94782C147.89 4.93288 156 13.2571 156 23.1982V41Z" ></path></svg>
                    </span>
                  </h1>
                  <div className="multi-step-form shadow">
                    <FormWizard
                      onComplete={handleComplete}
                      onSkip={handleSkip}
                      color="#00c875"
                      backButtonTemplate={backTemplate}
                      nextButtonTemplate={(handleNext) => (
                        <button className="action-btn continue-btn" onClick={()=>handleNextTab(handleNext, 0)}>
                          Continue
                        </button>
                      )}
                      finishButtonTemplate={(handleComplete) => (
                        <button className="action-btn finish-button" onClick={()=>handleNextTab(handleComplete, 1)}>
                          Finish
                        </button>
                      )}
                    >
                      <FormWizard.TabContent title="Company Info" icon="fi fi-rs-city">
                        <div className='row'>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Name <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Company Name" value={companyName} onChange={(e) => handleInputChange(e, setCompanyName)} />
                              {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Owner Name <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Company Owner Name" value={companyOwnerName} onChange={(e) => handleInputChange(e, setCompanyOwnerName)} />
                              {errors.companyOwnerName && <div className="text-danger">{errors.companyOwnerName}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Email <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Company Owner Name" value={email} onChange={(e) => handleInputChange(e, setEmail)} />
                              {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Location <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Location" value={location} onChange={(e) => handleInputChange(e, setLocation)} />
                              {errors.location && <div className="text-danger">{errors.location}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Phone No.<span className="text-danger">*</span></label>
                              <PhoneInput
                                country={'in'}
                                value={companyPhone}
                                onChange={phone => setCompanyPhone(phone)}
                              />
                              {errors.companyPhone && <div className="text-danger">{errors.companyPhone}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company WhatsApp No.<span className="text-danger">*</span></label>
                              <PhoneInput
                                country={'in'}
                                value={companyWhatsApp}
                                onChange={phone => setCompanyWhatsApp(phone)}
                              />
                              {errors.companyWhatsApp && <div className="text-danger">{errors.companyWhatsApp}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Strength</label>
                              <input type="text" className="form-control" placeholder="Company Strength" value={companyStrength} onChange={(e) => handleInputChange(e, setCompanyStrength)} />
                              {errors.companyStrength && <div className="text-danger">{errors.companyStrength}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Turnover</label>
                              <input type="text" className="form-control" placeholder="Company Turnover" value={companyTurnover} onChange={(e) => handleInputChange(e, setCompanyTurnover)} />
                              {errors.companyTurnover && <div className="text-danger">{errors.companyTurnover}</div>}
                           
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Company Logo</label>
                              <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".jpg,.png,.jpeg" style={{display:'none'}}/>
                              <div>
                                <button className="btn btn-primary" onClick={handleButtonClick}>Choose File</button>
                              </div>
                              {companyLogo && (
                                <div className="mt-2">
                                  <img src={URL.createObjectURL(companyLogo)} alt="Company Logo" className="img-fluid" style={{ maxWidth: '200px' }} />
                                </div>
                              )}
                              {errors.companyLogo && <div className="text-danger">{errors.companyLogo}</div>}
                            </div>
                          </div>
                        </div>
                      </FormWizard.TabContent>
                      <FormWizard.TabContent title="Contact Person Info" icon="fi fi-rr-user">
                        <div className='row'>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Contact Person Name <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Contact Person Name" value={contactPersonName} onChange={(e) => handleInputChange(e, setContactPersonName)} />
                              {errors.contactPersonName && <div className="text-danger">{errors.contactPersonName}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Contact Person Email <span className="text-danger">*</span></label>
                              <input type="email" className="form-control" placeholder="Contact Person Email" value={contactPersonEmail} onChange={(e) => handleInputChange(e, setContactPersonEmail)} />
                              {errors.contactPersonEmail && <div className="text-danger">{errors.contactPersonEmail}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Contact Person Phone No. <span className="text-danger">*</span></label>
                              <PhoneInput
                                country={'in'}
                                value={contactPersonPhone}
                                onChange={phone => setContactPersonPhone(phone)}
                              />
                              {errors.contactPersonPhone && <div className="text-danger">{errors.contactPersonPhone}</div>}
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="form-group">
                              <label className="form-label">Contact Person WhatsApp No.</label>
                              <PhoneInput
                                country={'in'}
                                value={contactPersonWhatsApp}
                                onChange={phone => setContactPersonWhatsApp(phone)}
                              />
                              {errors.contactPersonWhatsApp && <div className="text-danger">{errors.contactPersonWhatsApp}</div>}
                            </div>
                          </div>
                        </div>
                      </FormWizard.TabContent>
                    </FormWizard>
                  </div>
                </div>
              </div>
              <div className='col-lg-5 col-md-6 col-sm-12 login-right-col'>
                <div className='login-right-wrap'>
                  <div className='text-end imgBx'>
                    <img src={process.env.PUBLIC_URL + 'assets/images/login-right.webp'} alt="login" />
                  </div>
                  <div className='textBx'>
                    "Before Growthh, my to-do lists were scattered all around! Now, everything is in order and in one place."
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
